

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';
import ErrorList from './utilities/errorList';
import { ApiErrorInfo } from '../helpers/errorInfo';
import IngredientList from './ingredientList';
import InstructionList from './instructionList';
import { useAuth } from '../providers/AuthProvider';
import NotFound from './utilities/notFound';
import InsufficientRights from './utilities/insufficientRights';
import RecipeHelper, { RecipeIngredientType, RecipeInstructionType, RecipeType } from '../helpers/recipeHelper';
import useFetch from '../hooks/useFetch';
import Util from "../helpers/util";
import RecipeTags from "./recipeTags";
import InputControl from "./controls/inputControl";
import RecipeService from "../services/recipeService";
import RecipeUsage from "./recipeUsage";
import Fetch from "../helpers/fetch";
import { useOverlay } from "../providers/OverlayProvider";
import ModalDialog from "./utilities/modalDialog";
import BusyIndicator from "./controls/busyIndicator";

function getNextIdx(indices: number[]) {
    let nextIdx = 0;
    for(var i = 0; i < indices.length; i++) {
        console.log()
        if(indices[i] > nextIdx) {
            nextIdx = indices[i];
        }
    }
    return nextIdx + 1;
}

function isEmptyIngredient(item: RecipeIngredientType): boolean {
    return !item.text || !item.text.trim();
}

function isEmptyInstruction(item: RecipeInstructionType): boolean {
    return !item.text || !item.text.trim();
}

function removeEmptyIngredients(items: RecipeIngredientType[]): void {
    for(var i = 0; i < items.length; i++) {
        if(isEmptyIngredient(items[i])) {
            items.splice(i, 1);
            i--;
        }
    }
}

function removeEmptyInstructions(items: RecipeInstructionType[]): void {
    for(var i = 0; i < items.length; i++) {
        if(isEmptyInstruction(items[i])) {
            items.splice(i, 1);
            i--;
        }
    }
}

function getRecipeIngredientListWithEmptyIngredient(recipeIngredients: RecipeIngredientType[]): RecipeIngredientType[] {
    let lastItem = recipeIngredients[recipeIngredients.length - 1];
    if(!lastItem || !isEmptyIngredient(lastItem)) {
        let newItem = RecipeHelper.initRecipeIngredient(getNextIdx(recipeIngredients.map(x => Util.parseInt(x.index))));
        recipeIngredients.push(newItem);
    }
    return recipeIngredients;
}

function getRecipeInstructionListWithEmptyInstruction(recipeInstructions: RecipeInstructionType[]): RecipeInstructionType[] {
    let lastItem = recipeInstructions[recipeInstructions.length - 1];
    if(!lastItem || !isEmptyInstruction(lastItem)) {
        let newItem = RecipeHelper.initRecipeInstruction(getNextIdx(recipeInstructions.map(x => Util.parseInt(x.index))));
        recipeInstructions.push(newItem);
    }
    return recipeInstructions;
}

function getRecipeWithEmptyIngredient(recipe: RecipeType): RecipeType {
    let lastItem = recipe.ingredients[recipe.ingredients.length - 1];
    if(!lastItem || !isEmptyIngredient(lastItem)) {
        let newItem = RecipeHelper.initRecipeIngredient(getNextIdx(recipe.ingredients.map(x => Util.parseInt(x.index))));
        recipe.ingredients.push(newItem);
    }
    return recipe;
}

function getRecipeWithEmptyInstruction(recipe: RecipeType): RecipeType {
    let lastItem = recipe.instructions[recipe.instructions.length - 1];
    if(!lastItem || !isEmptyInstruction(lastItem)) {
        let newItem = RecipeHelper.initRecipeInstruction(getNextIdx(recipe.instructions.map(x => Util.parseInt(x.index))));
        recipe.instructions.push(newItem);
    }
    return recipe;
}

function getRecipeWithEmptyItems(recipe: RecipeType): RecipeType {
    recipe = getRecipeWithEmptyIngredient(recipe);
    return getRecipeWithEmptyInstruction(recipe);
}



type RecipeAddEditPropType = {
	isNew: boolean
} & React.PropsWithChildren

// props.isNew should be set if this will be a new recipe.
// params "recipeId" should be set if editing. In the 'view' component, user will click 'Edit' to go into edit mode (navigate here) 
export default function RecipeAddEdit(props: RecipeAddEditPropType) {

    const { userId } = useAuth();

    let location = useLocation();
    // console.log("location: " + JSON.stringify(location));
    // console.log("state: " + location.state);
    // console.log("from: " + location.state?.from);
    // console.log("pathname: " + location.state?.from?.pathname);
    let navigateToWhenDone: string = location.state?.from?.pathname || "/cookbook";
    let navigate = useNavigate();

    let [recipe, setRecipe] = useState<RecipeType>(() => RecipeHelper.initRecipe());
    let [recipeIngredients, setRecipeIngredients] = useState<RecipeIngredientType[]>(() => getRecipeIngredientListWithEmptyIngredient([]));
    let [recipeInstructions, setRecipeInstructions] = useState<RecipeInstructionType[]>(() => getRecipeInstructionListWithEmptyInstruction([]));
    let [recipeTags, setRecipeTags] = useState<string[]>([]);

    let [isBusy, setIsBusy] = useState<boolean>(false);
    let [errors, setErrors] = useState<string[]>([]);
    let [isNotFound, setIsNotFound] = useState(false);
    let { fetchStatus, getJson, postJson } = useFetch();

    let params = useParams();

    // "useEffect" fires after layout rendering. "useLayoutEffect" is fired before rendering and should be used if there needs to
    // be layout updates the user must see imediately.
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    useEffect(() => {
        console.log("UseEffect RecipeAddEdit");
        if(params.recipeId) {
            setIsBusy(true);
            RecipeService.get(getJson, params.recipeId).then(result => {
                if(result) {
                    let recipeResult = RecipeHelper.recipeFromApi(result);
                    setRecipe(getRecipeWithEmptyItems(recipeResult));
                    setRecipeIngredients(getRecipeIngredientListWithEmptyIngredient(recipeResult.ingredients));
                    setRecipeInstructions(getRecipeInstructionListWithEmptyInstruction(recipeResult.instructions));
                    setRecipeTags(recipeResult.tags);
                }
                else {
                    console.error("Recipe 'get' API call returned success but no data returned");
                    setIsNotFound(true);
                }
            }).catch((error) => {
                if(error instanceof ApiErrorInfo) {
                    if(error.isNotFound) {
                        setIsNotFound(true);
                    }
                }
            }).finally(() => {
                setIsBusy(false);
            });
        }
        else
        {
            if(props.isNew) {
                //no-op
            }
            else {
                console.error("Recipe in edit mode but no params defined... Unable to determine recipe to edit.");
                setIsNotFound(true);
            }
        }
    }, [userId, params.recipeId, props.isNew, getJson]);

    function recipeChanged(fieldName: string, fieldValue: any) {
        setRecipe((previousState) => {
            // Can use Object.assign or the object spread syntax to shallow clone.
            // This is the spread syntax "combine obj param 1 with obj param 2".
            return getRecipeWithEmptyItems({...previousState, ...{
                [fieldName]: fieldValue
            }});
        });
    }

    function onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        recipeChanged(fieldName, fieldValue);
    }

    function onSourceUrlChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = event.target.name;
        let fieldValue = event.target.value;
        recipeChanged(fieldName, fieldValue);
    }

    // function onTimeChanged(event: React.ChangeEvent<HTMLInputElement>) {
    //     let fieldName = event.target.name;
    //     let fieldValue = event.target.value;
    //     recipeChanged(fieldName, fieldValue);
    // }

    // function onTimeBlur(event: React.ChangeEvent<HTMLInputElement>) {
    //     let fieldName = event.target.name;
    //     let fieldValue = +(parseFloat(event.target.value) || 0).toFixed(2);
    //     console.log("fieldName: " + fieldName);
    //     console.log("fieldValue: " + fieldValue);
    //     recipeChanged(fieldName, fieldValue);
    // }

    const onIngredientsChanged = useCallback((ingredients: RecipeIngredientType[]) => {
        setRecipeIngredients([...getRecipeIngredientListWithEmptyIngredient(ingredients)]);
    }, []);

    const onInstructionsChanged = useCallback((instructions: RecipeInstructionType[]) => {
        setRecipeInstructions([...getRecipeInstructionListWithEmptyInstruction(instructions)]);
    }, []);

    function onImport() {
        setErrors([]);
        
        setIsBusy(true);
        postJson("recipe/import", recipe.sourceUrl).then(result => {
            console.table(result.recipe);
            let recipeResult: RecipeType = RecipeHelper.recipeFromApi(result.recipe);

            //TODO2: Use dispatch to update state of whole component - wasted a bunch of time troubleshooting because objs are managed seperately
            setRecipe(getRecipeWithEmptyItems(recipeResult));
            setRecipeIngredients(getRecipeIngredientListWithEmptyIngredient(recipeResult.ingredients));
            setRecipeInstructions(getRecipeInstructionListWithEmptyInstruction(recipeResult.instructions));
        }).catch(error => {
            setErrors(Util.getErrorsFromApiError(error));
        }).finally(() => {
            setIsBusy(false);
        });
    }

    function onImportIngredientFromText(text: string): Promise<void> {
        return new Fetch().postJson("recipe/importtext", text).then(result => {
            console.table(result.recipe);
            let recipeResult: RecipeType = RecipeHelper.recipeFromApi(result.recipe);
            setRecipeIngredients(getRecipeIngredientListWithEmptyIngredient(recipeResult.ingredients));
        })
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        var rIng = [...recipeIngredients];
        removeEmptyIngredients(rIng);
        // onIngredientsChanged([...recipeIngredients]);
        recipe.ingredients = rIng;

        var rIns = [...recipeInstructions];
        removeEmptyInstructions(rIns);
        // onInstructionsChanged([...recipeInstructions]);
        recipe.instructions = rIns;

        recipe.tags = recipeTags;

        setErrors([]);

        const validationErrors = RecipeHelper.validate(recipe);

        if(validationErrors.hasErrors) {
            setErrors(RecipeHelper.recipeValidationResultsAsGenericErrors(validationErrors));
            return;
        }

        
        const data = RecipeHelper.convertRecipeToApiJson(recipe);

        // console.log(recipe);
        // console.log(data);
        let updateFunc = props.isNew ? RecipeService.add : RecipeService.edit;

        setIsBusy(true);
        updateFunc(postJson, data).then(() => {
            // If going back to recipe 'view' remove the 'edit' history entry. This way when a user clicks 'back' they'll go to wherever
            // they were prior to first viewing the recipe. IE: Cookbook->recipe view->recipe edit->recipe view --- when hitting 'back' they should
            // be routed to cookbook instead of editing again.
            let isFromRecipeView: boolean = /recipe\/\d+/g.test(navigateToWhenDone);
            navigate(navigateToWhenDone, { replace: isFromRecipeView });
        }).catch((error: any) => {
            setErrors(Util.getErrorsFromApiError(error));
        }).finally(() => {
            setIsBusy(false);
        });

        return false;
    }

    function deleteRecipe() {
        setErrors([]);

        // eslint-disable-next-line no-restricted-globals
        if(!confirm("Are you sure?"))
        {
            return;
        }
        
        RecipeService.delete(postJson, recipe.id).then(result => {
            navigate("/cookbook");
        }).catch(error => {
            if(error instanceof ApiErrorInfo) {
                setErrors(error.errors);
            }
        });
    }

    function cancel() {
        // Could use '-1' as param to go back to the last page, but user may not be in the application.
        // 'replace: true' doesn't 'push' this entry into the history but replaces it with the new nav target
        // - https://reactrouter.com/en/main/hooks/use-navigate#optionsreplace
        navigate(navigateToWhenDone, { replace: true });
    }

    const recipeTagsContent = useMemo(() => {
        return (
            <RecipeTags selectedTags={recipeTags} updateTags={((tags) => setRecipeTags(tags))} />
        )
    }, [recipeTags])

    // To prevent re-rendering the ingredients all the time, could memoize the control and then make recipe fields
    // individual fields (or at least split out the 'list' fields). This way when recipe changes trigger re-render, the
    // ingredients list will remain the same object reference
    const ingredientsList = useMemo(() => {
        return (
            <IngredientList items={recipeIngredients} onItemsChanged={onIngredientsChanged} />
        )
    }, [onIngredientsChanged, recipeIngredients]);

    const instructionsList = useMemo(() => {
        return (
            <InstructionList items={recipeInstructions} onItemsChanged={onInstructionsChanged} />
        )
    }, [onInstructionsChanged, recipeInstructions]);

    if(isNotFound) {
        return <NotFound message="The recipe you are looking for does not exist or has been moved"/>
    }

    if(fetchStatus.isComplete() && !props.isNew && recipe?.owner?.ownerId !== userId) {
        return <InsufficientRights />
    }

    if(recipe) {
        // The plus sign drops extra zeroes at the end. It changes the result from a string into a number again
        //TODO2: prepTime is a 'number' by typing, but the actual type once it is changed from the textbox onChange is a string. '.toFixed' does not exist on a string.
        //      Move these fields to be a string and convert them to a number at some point
        recipe.totalTime = +(recipe.prepTime || 0).toFixed(2) + +(recipe.cookTime || 0).toFixed(2) + +(recipe.inactiveTime || 0).toFixed(2);
        recipe.totalTime = +recipe.totalTime.toFixed(2);
    }

    return (
        <BusyIndicator showIndicator={isBusy}>
            <div className="">
                <form onSubmit={onSubmit} className="needs-validation">
                    <div className="card shadow-lg mb-5 bg-body rounded">
                        <div className="card-header">
                            <h3 className="text-center">Add/Edit recipe</h3>
                        </div>
                        <div className="card-body">
                            <div className="card-body shadow-sm mt-3">
                                <div className="form-group">
                                    <div className="input-group">
                                        <InputControl type="text" maxLength={2048} label="Source url" placeholder="Add a link to the original recipe if it's from another site!" name="sourceUrl" value={recipe.sourceUrl} onChange={onSourceUrlChanged} readOnly={recipe.imported}/>
                                        {recipe.sourceUrl ? (
                                        <div className="input-group-append d-flex">
                                            <a type="text" className="btn btn-link mt-auto" href={recipe.sourceUrl} target="_blank" rel="noopener noreferrer">Go to source!</a>
                                        </div>
                                        ) : null}
                                    </div>
                                </div>
                                {recipe.id ? null : (<button type="button" className="btn btn-secondary" onClick={onImport} disabled={!recipe.sourceUrl}>Import from source url</button>)}
                            </div>
                            <ImportFromCopyPaste onImportText={onImportIngredientFromText}/>
                            <div className="card-body shadow-sm mt-3">
                                <h3>General information</h3>
                                <div className="form-group mt-4">
                                    <InputControl type="text" maxLength={50} label="Recipe name" autoFocus={props.isNew} placeholder="Enter recipe name" name="name" value={recipe.name} onChange={onNameChanged} required={true} />
                                </div>
                                {/* <div className="row mt-3">
                                    <div className="col-md-3 form-group">
                                        <label>Prep time (minutes)</label>
                                        <input type="text" className="form-control" name="prepTime" value={recipe.prepTime} onChange={onTimeChanged} onBlur={onTimeBlur}/>
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label>Cook time (minutes)</label>
                                        <input type="text" className="form-control" name="cookTime" value={recipe.cookTime} onChange={onTimeChanged} onBlur={onTimeBlur}/>
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label>Inactive time (minutes)</label>
                                        <input type="text" className="form-control" name="inactiveTime" value={recipe.inactiveTime} onChange={onTimeChanged} onBlur={onTimeBlur}/>
                                    </div>
                                    <div className="col-md-3 form-group">
                                        <label>Total time (minutes)</label>
                                        <input type="text" className="form-control" name="totalTime" value={recipe.totalTime} readOnly/>
                                    </div>
                                </div> */}
                            </div>
                            <div className="card-body shadow-sm mt-3">
                                <h3>Tags</h3>
                                {recipeTagsContent}
                            </div>
                            <div className="card-body shadow-sm mt-3">
                                <h3>Ingredients</h3>
                                {ingredientsList}
                            </div>
                            <div className="card-body shadow-sm mt-3">
                                <h3>Instructions</h3>
                                {instructionsList}
                            </div>
                            <ErrorList errors={errors}></ErrorList>
                        </div>
                        <div className="card-footer sticky-bottom bg-light bg-opacity-75 d-flex flex-column flex-column-reverse flex-sm-row">
                            <div className="d-flex flex-fill align-items-center justify-content-center justify-content-sm-start">
                                {!recipe.id || <span className="fst-italic me-3 fs-smaller text-center">Created on {recipe.creationDate?.toString()}</span>}
                            </div>
                            <div className="d-flex justify-content-center justify-content-sm-end">
                                <button type="submit" className="btn btn-primary me-2">Save</button>
                                <button type="button" onClick={cancel} className="btn btn-secondary me-2">Cancel</button>
                                {!recipe.id || <button type="button" onClick={deleteRecipe} className="btn btn-danger">Delete</button>}
                            </div>
                        </div>
                    </div>
                </form>

                <RecipeUsage recipeId={recipe.id} />
            </div>
        </BusyIndicator>
    );
}


type ImportFromCopyPastePropType = {
    onImportText(text: string): Promise<void>
}

function ImportFromCopyPaste(props: ImportFromCopyPastePropType) {
    const {setOverlayContent} = useOverlay();

    function onImport(text: string): void {
        props.onImportText(text).then(() => {
            setOverlayContent(null);
        });
    }

    function onShowModal() {
        setOverlayContent(
            <PasteContentModalDialog onCancel={() => setOverlayContent(null)} onCommitContent={onImport}/>
        )
    }

    return (
        <button type="button" className="btn btn-secondary" onClick={onShowModal}>Import ingredients (copy/paste text)</button>
    );
}

type PasteContentModalDialogPropType = {
    onCancel(): void
    onCommitContent(content: string): void
}

function PasteContentModalDialog(props: PasteContentModalDialogPropType) {
    const [value, setValue] = useState("");

    function onValueChanged(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setValue(e.currentTarget.value);
    }

    return (
        <ModalDialog titleContent="Paste ingredients" onClose={props.onCancel} onOk={() => props.onCommitContent(value)}>
            <span className="text-danger">Clicking 'ok' will clear any existing ingredients from the recipe</span>
            <textarea autoFocus={true}
                name={"textContent"}
                value={value}
                onChange={onValueChanged}
                placeholder={"Paste ingredient content here"}
                required={true}
                className="form-control"/>
        </ModalDialog>
    );
}