import { useEffect, useState } from "react";
import BusyIndicator, { BusyIndicatorSize } from "./busyIndicator";

type AsyncCheckboxStatusButtonPropType = {
    disabled?: boolean,
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean>,
    onFinalized?(isSuccess: boolean): void
}

export default function AsyncCheckboxStatusButton(props: AsyncCheckboxStatusButtonPropType) {
    const [ isBusy, setIsBusy ] = useState(false);
    const [ isSuccess, setIsSuccess ] = useState<boolean | null>(null);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if(typeof isSuccess === 'boolean')
        {
            timeoutId = setTimeout(() => {
                timeoutId = null;
                setIsSuccess(null);
                
                if(props.onFinalized) {
                    props.onFinalized(isSuccess);
                }
            }, 1500);
        }

        // Cleanup function to avoid memory leaks. When component is unmounted (leaves the screen) any
        // subscriptions, timers, timeouts, etc must be disposed of.
        return () => {
            if(timeoutId || timeoutId === 0) {
                clearTimeout(timeoutId);
            }
        };
    }, [isSuccess, props]);

    function onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if(isBusy) {
            return;
        }

        setIsBusy(true);
        
        // Perform action
        props.onClick(e).then((isSuccess: boolean) => {
            // Action complete - show result status
            setIsBusy(false);
            setIsSuccess(isSuccess);
        });
    }

    const isIndicatorActive = typeof isSuccess === 'boolean';
    
    let btnClass = "btn px-1 py-0 border-0";
    if(isIndicatorActive) {
        btnClass += isSuccess ? " bg-success" : " bg-danger";
    }
    
    let btnContent = (
        <div>
            <i className="bi-check-square opacity-25 position-absolute"></i>
            <i className="bi-square"></i>
        </div>
    );

    if(isIndicatorActive) {
        let btnIcon = isSuccess ? "bi-check-square" : "bi-slash-square";
        btnContent = (
            <i className={btnIcon}></i>
        );
    }
    
    
    return (
        <BusyIndicator showIndicator={isBusy} indicatorSize={BusyIndicatorSize.SMALL} className="d-inline-block">
            <button className={btnClass} type="button" disabled={isBusy || props.disabled} onClick={onClick}>
                {btnContent}
            </button>
        </BusyIndicator>
    )
}