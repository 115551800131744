

import React, { useCallback, useEffect, useReducer } from 'react';
import useFetch from '../hooks/useFetch';
import RecipeHelper, { RecipeType } from '../helpers/recipeHelper';
import RecipeTags from "./recipeTags";
import Accordion from "./controls/accordion";
import { IngredientAutoCompleteTextbox } from "./controls/ingredientAutoCompleteTextbox";
import DeleteButton from "./controls/deleteButton";
import LocalStorage from "../helpers/localStorage";
import InputControl from "./controls/inputControl";
import RecipeService from "../services/recipeService";
import { ApiErrorInfo } from "../helpers/errorInfo";
import ErrorList from "./utilities/errorList";
import BusyIndicator from "./controls/busyIndicator";
import { IRecipeSearchState, RECIPESEARCH_ACTION_TYPE, RecipeSearchReducer } from "./recipeSearchReducer";

type RecipeSearchProps = {
	onSearchInitiated(): void,
    onSearchCompleted(results: RecipeType[]): void,
    onQuickAddSuccess(recipe: RecipeType): void
} & React.PropsWithChildren

const initialState: IRecipeSearchState = {
    searchText: "",
    searchTags: [],
    ingredientSearchText: "",
    searchIngredients: [],
    isMatchOnAllIngredients: LocalStorage.getLocalStorageBool(LocalStorage.KEY_REC_ISMATCHONALLINGREDIENTS),
    isTypeAheadSearchRunning: false,
    quickAddErrors: [],
    quickAddInProgress: false
}

export default function RecipeSearch(props: RecipeSearchProps) {

    let [state, dispatch] = useReducer(RecipeSearchReducer, initialState);

    let { getJson, postJson } = useFetch();



    const onClickQuickAdd = useCallback(() => {
        if(state.quickAddInProgress) {
            return;
        }

        dispatch({ type: RECIPESEARCH_ACTION_TYPE.QUICK_ADD_INITIATED });
        
        const errors: string[] = [];
        let addedRecipe: RecipeType;
    
        let recipe = RecipeHelper.initRecipe();
        recipe.name = state.searchText;
        const data = RecipeHelper.convertRecipeToApiJson(recipe);
        RecipeService.add(postJson, data).then((recipeId) => {
            return RecipeService.get(getJson, recipeId).then(result => {
                if(result) {
                    addedRecipe = RecipeHelper.recipeFromApi(result);
                }
                else {
                    errors.push("Recipe was added but could not be retrieved. Please search for the recipe.");
                }
            });
        }).catch((error: any) => {
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        errors.push(...fieldErr.errors);
                    });
                }
                errors.push(...error.errors);
            }
            else
            {
                //TODO2: Error handling elsewhere should account for unexpected errors. ie: TypeError (or some other JS error that happens in a service method)
                throw error;
            }
        }).finally(() => {
            if(errors.length === 0) {
                props.onQuickAddSuccess(addedRecipe);
            }
            dispatch({
                type: RECIPESEARCH_ACTION_TYPE.QUICK_ADD_COMPLETED,
                payload: { errors: errors }
            });
        });
    }, [getJson, postJson, props, state.quickAddInProgress, state.searchText]);

    const search = useCallback((): Promise<RecipeType[]> => {
        let data = {
            name: state.searchText,
            owner: "",
            ownerId: "",
            tags: state.searchTags,
            ingredients: state.searchIngredients,
            isMatchOnAllIngredients: state.isMatchOnAllIngredients
        };
        
        //TODO2: Pagination
        //TODO2: Update to use paging (RecipeService.search)?
        return getJson("recipe/search", data).then((result: any) => {
            return RecipeHelper.recipesFromApi(result.recipes);
        });
    }, [getJson, state.isMatchOnAllIngredients, state.searchIngredients, state.searchTags, state.searchText]);
    
    const onClickSearch = useCallback((): void => {
        props.onSearchInitiated();
        search().then((results: RecipeType[]) => {
            props.onSearchCompleted(results);
        });
    }, [props, search]);
    

    useEffect(() => {
        // Search automatically when search text is long enough. Majority of searching will be by name.
        if(state.searchText && state.searchText.length > 3) {

            //TODO2: Optimize this. When typing quickly the fetch should be aborted or the query should only be done if no typing in x milliseconds
            //       https://stackoverflow.com/questions/31061838/how-do-i-cancel-an-http-fetch-request
            //       Could also get the results for length of 3 and then filter client side after length of 3.
            onClickSearch();
        }
    }, [onClickSearch, state.searchText]);
    
	let isSearchAllowed = state.searchText || state.searchTags.length > 0 || state.searchIngredients.length > 0 || state.ingredientSearchText.length > 1;

    return (
        <BusyIndicator showIndicator={state.quickAddInProgress}>
            <div>
                <div className="form-group mb-2">
                    <InputControl type="text" label="Recipe name" name="name" value={state.searchText} onChange={(e) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.SEARCH_TEXT_CHANGED, payload: e.target.value})} />
                </div>
                <div className="d-flex flex-row-reverse mb-2">
                    <button className="btn btn-secondary col-sm-6" disabled={!state.searchText || state.quickAddInProgress} onClick={() => onClickQuickAdd()}>Quick add</button>
                </div>
                <ErrorList errors={state.quickAddErrors}></ErrorList>
                <Accordion id="tags" label={<div><span>Recipe tags</span>{state.searchTags.length > 0 ? <i className="bi-slash-circle text-warning ms-2"></i> : null}</div>}>
                    <RecipeTags selectedTags={state.searchTags} updateTags={(tags: string[]) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.SEARCH_TAGS_CHANGED, payload: tags })} />
                </Accordion>
                <Accordion id="ingredients" label={<div><span>Ingredients</span>{state.searchIngredients.length > 0 ? <i className="bi-slash-circle text-warning ms-2"></i> : null}</div>}>
                    <div className="mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="ing_matchOnAll" id="ing_matchOnAll" checked={state.isMatchOnAllIngredients} onChange={(e) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.IS_MATCH_ON_ALL_CHANGED, isMatchOnAll: e.target.checked })}/>
                            <label className="form-check-label" htmlFor="ing_matchOnAll">Search for recipes containing <b>all</b> selected ingredients</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="ing_matchOnAny" id="ing_matchOnAny" checked={!state.isMatchOnAllIngredients} onChange={(e) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.IS_MATCH_ON_ALL_CHANGED, isMatchOnAll: !e.target.checked })}/>
                            <label className="form-check-label" htmlFor="ing_matchOnAny">Search for recipes containing <b>any</b> selected ingredients</label>
                        </div>
                    </div>
                    <IngredientAutoCompleteTextbox isReadOnly={false}
                                                        includeRecipeAsIngredient={false}
                                                        value={state.ingredientSearchText}
                                                        onTextChanged={(e) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_SEARCH_TEXT_CHANGED, payload: e })}
                                                        onSelectItem={(e) => dispatch({ type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_ADDED, payload: e.name })}
                                                        />
                    <div>
                        {state.searchIngredients.map((ingFilter: string) => {
                            return (
                                <div key={ingFilter} className="d-inline-block border rounded-pill bg-light px-2 mb-1 me-1 text-nowrap">
                                    <span className="me-1">{ingFilter}</span>
                                    <DeleteButton onClick={() => dispatch({ type: RECIPESEARCH_ACTION_TYPE.INGREDIENT_REMOVED, payload: ingFilter })} />
                                </div>
                            )
                        })}
                    </div>
                </Accordion>
                <div className="d-flex flex-row-reverse mt-2">
                    <button className="btn btn-secondary col-sm-6" disabled={!isSearchAllowed} onClick={onClickSearch}>Search</button>
                </div>
            </div>
        </BusyIndicator>
    );
}
