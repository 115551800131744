
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useFetch from '../hooks/useFetch';
import GroceryListHelper, { ApiGroceryListType, GroceryListItemType, GroceryListType } from "../helpers/groceryListHelper";
import ErrorList from "../components/utilities/errorList";
import { ApiErrorInfo } from "../helpers/errorInfo";
import Util from "../helpers/util";
import styles from "../components/groceryList/groceryList.module.scss";
import { GroceryListing } from "../components/groceryList/groceryListing";
import { useOverlay } from "../providers/OverlayProvider";
import LocalStorage from "../helpers/localStorage";
import { Link } from "react-router-dom";
import { GLHistoryListing } from "../components/groceryListHistory/glHistoryListing";
import GLHistoryHelper, { ApiGLHistoryItem, GLHistoryItem } from "../helpers/glHistoryHelper";
import BusyIndicator from "../components/controls/busyIndicator";


export default function GroceryListView() {
    const { fetchStatus, getJson, postJson } = useFetch();
    const [ groceryList, setGroceryList] = useState<GroceryListType>(() => {
        let groceryList = GroceryListHelper.initGroceryList();
        return GroceryListHelper.getGroceryListWithEmptyItem(groceryList);
    });

    const [ glHistoryItems, setGLHistoryItems] = useState<GLHistoryItem[]>([]);

    const [isShoppingView, setIsShoppingView] = useState<boolean>(() => {
        return LocalStorage.getLocalStorageBool(LocalStorage.KEY_GL_ISSHOPPINGVIEW);
    });
    const [refreshData, setRefreshData] = useState<boolean>(true);
    const { showSuccessIndicatorOverlay } = useOverlay();

    const [saveErrors, setSaveErrors] = useState<string[]>([]);

    const onGroceryListItemsChanged = useCallback((groceryListItems: GroceryListItemType[]) => {
        setGroceryList((oldValue) => {
            let updatedList = {...oldValue};
            updatedList.items = groceryListItems;
            GroceryListHelper.reIndexItems(updatedList.items);
            return isShoppingView ? updatedList : GroceryListHelper.getGroceryListWithEmptyItem(updatedList);
        });
    }, [isShoppingView]);

    function onGroceryListItemChanged(oldItem: GroceryListItemType, newItem: GroceryListItemType) {
        setGroceryList((old) => {
            let updatedList = {...old};
            let modifiedItemIdx = updatedList.items.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, oldItem));
            if(modifiedItemIdx !== -1) {
                updatedList.items.splice(modifiedItemIdx, 1, newItem);
            }
            return isShoppingView ? updatedList : GroceryListHelper.getGroceryListWithEmptyItem(updatedList);
        });
    }

    const removeItem = useCallback((item: GroceryListItemType) => {
            let listItems = [...groceryList.items];
            let removedItemIdx = listItems.findIndex(x => GroceryListHelper.isMatchingGroceryListItem(x, item));
            if(removedItemIdx >= 0) {
                listItems.splice(removedItemIdx, 1);
                onGroceryListItemsChanged(listItems);
            }
        }, [groceryList.items, onGroceryListItemsChanged]);

    const clearAllItems = useCallback(() => {
        onGroceryListItemsChanged([]);
    }, [onGroceryListItemsChanged]);

    const removeRecipeFromGL = useCallback((recipeId: string) => {
        setGroceryList((old) => {
            let updatedList = {...old};
            GroceryListHelper.removeRecipeFromGL(updatedList.items, recipeId);
            GroceryListHelper.reIndexItems(updatedList.items);
            return isShoppingView ? updatedList : GroceryListHelper.getGroceryListWithEmptyItem(updatedList);
        });
    }, [isShoppingView]);
    
    function onIsShoppingViewChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if(event.target.checked) {
            setGroceryList((old) => {
                let updatedList = {...old};
                GroceryListHelper.removeEmptyItems(updatedList.items);
                GroceryListHelper.reIndexItems(updatedList.items);
                return updatedList;
            });
        }
        else {
            setGroceryList((old) => {
                let updatedList = {...old};
                GroceryListHelper.reIndexItems(updatedList.items);
                return GroceryListHelper.getGroceryListWithEmptyItem(updatedList);
            });
        }
        setIsShoppingView(event.target.checked);
        LocalStorage.setLocalStorageBool(LocalStorage.KEY_GL_ISSHOPPINGVIEW, event.target.checked);
    }

    function sortByDepartment() {
        setGroceryList((old) => {
            let updatedList = {...old};
            let sortedItems = updatedList.items;
            let sortOrder = GroceryListHelper.getDefaultDepartmentSortOrder();
            let nextAddIdx = 0;
            sortOrder.forEach(department => {
                for(var i = nextAddIdx; i < sortedItems.length; i++) {
                    let item = sortedItems[i];
                    if(!GroceryListHelper.isEmptyItem(item) && item.department === department && i >= nextAddIdx) {
                        var removedItem = sortedItems.splice(i, 1);
                        sortedItems.splice(nextAddIdx, 0, removedItem[0]);
                        nextAddIdx++;
                        i--;
                        continue;
                    }
                }
            });
            
            updatedList.items = sortedItems;
            GroceryListHelper.reIndexItems(updatedList.items);
            return isShoppingView ? updatedList : GroceryListHelper.getGroceryListWithEmptyItem(updatedList);
        });
    }

    const addItemToGL = useCallback((item: GLHistoryItem): Promise<boolean> => {
        setGroceryList((prev) => {
            let gl = {...prev};
            let glItems = [...gl.items];
            let newItemIdx = glItems.length;
            if(GroceryListHelper.isEmptyItem(glItems[glItems.length - 1])) {
                newItemIdx--;
            }
            const glItem = GroceryListHelper.glItemFromGLHistoryItem(item, newItemIdx);
            glItems.splice(newItemIdx, 0, glItem);
            GroceryListHelper.reIndexItems(glItems);
            gl.items = glItems;
            return gl; //isShoppingView ? gl : GroceryListHelper.getGroceryListWithEmptyItem(gl);
        })
        return Promise.resolve(true);
    }, []);

    const onGLHistoryRemoved = useCallback((item: GLHistoryItem) => {
        setGLHistoryItems(prev => {
            let state = [...prev];
            const idx = state.findIndex(x => x.id === item.id);
            if(idx >= 0) {
                state.splice(idx, 1);
            }
            return state;
        });
    }, []);

    const queryGLHistoryItems = useCallback(async () => {
        let result = await getJson("glhistory/lastpurchased");
        let parsedItems: GLHistoryItem[] = [];
        if(result && result.glHistories && Array.isArray(result.glHistories)) {
            (result.glHistories as ApiGLHistoryItem[]).forEach(x => parsedItems.push(GLHistoryHelper.initFromApi(x)!));
        }
        setGLHistoryItems(parsedItems);
    }, [getJson]);

    const onItemPurchased = useCallback(async (item: GroceryListItemType) => {
        removeItem(item);
        await queryGLHistoryItems();
    }, [queryGLHistoryItems, removeItem])

    const onGLHistoryUpdated = useCallback(async (item: GLHistoryItem) => {
        await queryGLHistoryItems();
    }, [queryGLHistoryItems]);

    function save() {
        setSaveErrors([]);

        let data = {...groceryList};
        //TODO2: When cloning objects, need to be sure every sub-property (of type object) is also cloned so the original is not modified
        data.items = [...groceryList.items];
        GroceryListHelper.removeEmptyItems(data.items);
        
        GroceryListHelper.reIndexItems(data.items);
        let apiList = GroceryListHelper.convertToApiJson(data);

        postJson("grocerylist/save", apiList).then(() => {
			showSuccessIndicatorOverlay();
            setRefreshData(true);
        }).catch((error) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setSaveErrors(error.errors);
            }
        });
    }

    const removeItemsDropdown = useMemo(() => {
        let recipes = GroceryListHelper.getRecipesInGroceryList(groceryList.items);
        return (
            <div className="dropdown mb-2">
                <button className="btn btn-secondary dropdown-toggle w-100" disabled={GroceryListHelper.isEmpty(groceryList)} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Remove items
                </button>
                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                    <li><button className="dropdown-item" onClick={clearAllItems}>All</button></li>
                    {recipes.length > 0 ? <li><hr className="dropdown-divider"/></li> : null}
                    {recipes.length > 0 ? <li><h6 className="dropdown-header">By recipe</h6></li> : null}
                    {recipes.map(recipe => {
                        return <li key={recipe.id}><button className="dropdown-item" onClick={() => removeRecipeFromGL(recipe.id)}>{recipe.name}</button></li>
                    })}
                </ul>
            </div>
        )
    }, [clearAllItems, groceryList, removeRecipeFromGL]);

    useEffect(() => {
        if(refreshData) {
            getJson("grocerylist/").then((groceryListResult: ApiGroceryListType) => {
                let initializedGL = GroceryListHelper.initGroceryListFromApi(groceryListResult) || GroceryListHelper.initGroceryList();
                let groceryList = initializedGL;
                
                // If not in shopping view show an empty item
                if(!isShoppingView) {
                    groceryList = GroceryListHelper.getGroceryListWithEmptyItem(initializedGL);
                    // If grocery list is empty don't allow user to be in 'shopping view'
                    if(GroceryListHelper.isEmpty(groceryList)) {
                        setIsShoppingView(false);
                    }
                }

                // Sort by item index
                groceryList.items.sort((a, b) => (Util.parseInt(a.index) < Util.parseInt(b.index)) ? -1 : 1);
                setGroceryList(groceryList);
            }).catch((error) => {
                console.log(error);
            }).then(queryGLHistoryItems)
            .finally(() => {
                setRefreshData(false);
            });
        }
    }, [getJson, isShoppingView, queryGLHistoryItems, refreshData]);

    return (
        <BusyIndicator showIndicator={!fetchStatus.isComplete()}>
            <div className="d-flex flex-grow-1">
                <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                    <div className="card-header">
                        <h3 className="text-center">Grocery list</h3>
                    </div>
                    <div className={"card-body " + styles.groceryListStyle}>
                        <div className="row">
                            <div className="col-sm-6 text-center">
                                <input type="checkbox" id="shoppingView" className="me-1 mb-2" checked={isShoppingView} onChange={onIsShoppingViewChanged}/>
                                <label htmlFor="shoppingView">Shopping view</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                {removeItemsDropdown}
                            </div>
                            <div className="col-sm-3">
                                <button type="button" onClick={sortByDepartment} className="btn btn-secondary mb-2 w-100">Sort by department</button>
                            </div>
                            <div className="col-sm-3">
                                <button type="button" onClick={save} className="btn btn-secondary mb-2 w-100">Save</button>
                            </div>
                            <div className="col-sm-3">
                                <Link to={"/grocery-list/upload/"} className="btn btn-secondary mb-2 w-100">Upload an image</Link>
                            </div>
                        </div>
                        <ErrorList errors={saveErrors} />
                        <GroceryListing isShoppingView={isShoppingView}
                                        groceryList={groceryList}
                                        onItemPurchased={onItemPurchased}
                                        onGroceryListItemChanged={onGroceryListItemChanged}
                                        onGroceryListItemsChanged={onGroceryListItemsChanged}/>
                        <div className="mt-5">
                            <GLHistoryListing items={glHistoryItems} addItemToGL={addItemToGL} onItemRemoved={onGLHistoryRemoved} onItemUpdated={onGLHistoryUpdated}/>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="float-end">
                            <button type="button" onClick={save} className="btn btn-secondary">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </BusyIndicator>
    );
}