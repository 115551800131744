
import React, { useCallback } from 'react';
import UnitOfMeasureDropdown from "./controls/unitOfMeasureDropdown";
import IngredientQuantity from "./controls/ingredientQuantity";
import RecipeHelper, { RecipeIngredientType } from "../helpers/recipeHelper";
import styles from "./ingredientList.module.scss";
import { IngredientAutoCompleteTextbox } from "./controls/ingredientAutoCompleteTextbox";
import IngredientHelper, { IIngredientSearchResult } from "../helpers/ingredientHelper";
import useFetch from "../hooks/useFetch";
import { ApiErrorInfo } from "../helpers/errorInfo";
import { Link, useLocation } from "react-router-dom";
import InputControl from "./controls/inputControl";
import RecipeService from "../services/recipeService";

function getItemKey(item: RecipeIngredientType): string {
    return item.index;
}

type IngredientListPropType = {
	items: RecipeIngredientType[],
    onItemsChanged(items: RecipeIngredientType[]): void,
} & React.PropsWithChildren

// Table tut: https://www.youtube.com/watch?v=dYjdzpZv5yc
// Fragments (multiple child elements): https://reactjs.org/docs/fragments.html

// React tut: https://www.youtube.com/watch?v=Ke90Tje7VS0

// Drag/drop: https://www.w3schools.com/jsref/event_ondragend.asp

// When your own table gets too complicated: https://react-table.tanstack.com/docs/overview
export default function IngredientList(props: IngredientListPropType) {
    const { getJson } = useFetch();
    let location = useLocation();

    let rows = props.items;
    let rowDragged: HTMLElement | null = null;
    let lastRowEntered: HTMLElement | null = null;
    

    function printElementDetails(element: HTMLElement | null): string {
        if(!element) {
            return "Element null";
        }
        return "Idx(table):" + getRowIndexInTable(element) + ", Idx(row): " + getRowIndex(element) + ", key: " + getRowKey(element);
    }

    function getRowKey(htmlElement: HTMLElement): string {
        // Get closest parent that is a TR tag
        return htmlElement.closest("tr")?.dataset.key || "";
    }

    const getRowIndex = useCallback((element: HTMLElement): number => {
        const elementRowKey = getRowKey(element);
        for (let i = 0; i < rows.length; i++) {
            if(getItemKey(rows[i]) === elementRowKey) {
                return i;
            }
        }
        return -1;
    }, [rows]);

    function getRowIndexInTable(htmlElement: HTMLElement) {
        let tbRows: Element[] = [];
        let tBody: HTMLElement | null = htmlElement;
        let element: HTMLElement | null = htmlElement;
        while(tBody) {
            if(tBody.tagName === "TBODY") {
                tbRows = Array.from(tBody.children);
                break;
            }
            tBody = tBody.parentElement;
        }

        while(element) {
            if(element.tagName === "TR") {
                break;
            }
            element = element.parentElement;
        }

        return tbRows.indexOf(htmlElement);
    }

    function onTextChanged(value: string, row: RecipeIngredientType)
    {
        let rowIdx = rows.findIndex(x => x.index === row.index);
        rows[rowIdx].text = value;
        rows[rowIdx].ingredient = null;
        // If text changes, make sure the recipeIngredient is recreated to ensure any ingredient_recipe is wiped out
        rows[rowIdx].ingredientRecipe = null;
        rows[rowIdx].isRecipe = false;
        props.onItemsChanged(rows);
    }

    function onSearchResultSelected(value: IIngredientSearchResult, row: RecipeIngredientType)
    {
        let rowIdx = rows.findIndex(x => x.index === row.index);
        let oldRecipeIngredient = rows[rowIdx];
        rows[rowIdx] = RecipeHelper.initRecipeIngredient(Number(rows[rowIdx].index));
        rows[rowIdx].text = value.name;
        let promise = Promise.resolve();
        if(value.recipeId) {
            // load recipe
            promise = new Promise((resolve) => {
                RecipeService.get(getJson, value.recipeId).then(result => {
                    if(result) {
                        let recipe = RecipeHelper.recipeFromApi(result);
                        rows[rowIdx].text = recipe.name;
                        rows[rowIdx].ingredientRecipe = recipe;
                        rows[rowIdx].isRecipe = true;
                    }
                    else {
                        console.error("Recipe 'get' API call returned success but no data returned");
                    }
                }).catch((error) => {
                    if(error instanceof ApiErrorInfo) {
                        if(error.isNotFound) {
                            console.error(`Could not find recipe '${value.recipeId}'`);
                        }
                    }
                }).finally(() => {
                    resolve();
                });
            });
        }
        else {
            let ingredient = IngredientHelper.initIngredient();
            ingredient.id = value.ingredientId;
            ingredient.name = value.name;
            ingredient.defaultUnit = value.defaultUnit;
            rows[rowIdx].ingredient = ingredient;
            rows[rowIdx].unit = value.defaultUnit;
            if(oldRecipeIngredient)
            {
                rows[rowIdx].qtyWhole = oldRecipeIngredient.qtyWhole;
                rows[rowIdx].qtyNumerator = oldRecipeIngredient.qtyNumerator;
                rows[rowIdx].qtyDenominator = oldRecipeIngredient.qtyDenominator;
                if(!rows[rowIdx].unit)
                {
                    rows[rowIdx].unit = oldRecipeIngredient.unit;
                }
            }
        }
        promise.then(() => {
            props.onItemsChanged(rows);
        });
    }

    function onQtyWholeChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        console.log("onQtyWholeChanged: " + newValue);
        rows[rowIdx].qtyWhole = newValue;
        props.onItemsChanged(rows);
    }

    function onQtyNumeratorChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        console.log("onQtyNumeratorChanged: " + newValue);
        rows[rowIdx].qtyNumerator = newValue;
        props.onItemsChanged(rows);
    }

    function onQtyDenominatorChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        console.log("onQtyDenominatorChanged: " + newValue);
        rows[rowIdx].qtyDenominator = newValue;
        props.onItemsChanged(rows);
    }

    function onUnitChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        console.log("onUnitChanged: " + newValue);
        rows[rowIdx].unit = newValue;
        props.onItemsChanged(rows);
    }

    function onNoteChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        var rowIdx = getRowIndex(event.target);
        console.log("onNoteChanged: " + newValue);
        rows[rowIdx].note = newValue;
        props.onItemsChanged(rows);
    }

    function removeRow(event: React.MouseEvent<HTMLButtonElement>) {
        let eventIndex = getRowIndex(event.target as HTMLElement);
        rows.splice(eventIndex, 1);
        props.onItemsChanged(rows);
    }

    function toggleRowType(event: React.MouseEvent<HTMLButtonElement>) {
        let rowIdx = getRowIndex(event.target as HTMLElement);
        rows[rowIdx].isHeader = !rows[rowIdx].isHeader;
        props.onItemsChanged(rows);
    }




    function onRowDragStart(event: React.DragEvent<HTMLTableRowElement>) {
        console.log("onRowDragStart ::: " + printElementDetails(event.target as HTMLElement));
        rowDragged = event.target as HTMLElement;

        // let tbRows = Array.from(rowDragged.parentNode.children);

        // Could set styles for drag row here
    }

    function onRowDrag(event: React.DragEvent<HTMLTableRowElement>) {
        // console.log("onRowDrag: " + getRowKey(event.target));
    }

    function onRowDragEnd(event: React.DragEvent<HTMLTableRowElement>) {
        // The final 'drag' event called regardless of whether something was moved or not
        console.log("onRowDragEnd: " + printElementDetails(event.target as HTMLElement));

        rowDragged = null;
        lastRowEntered = null;
        props.onItemsChanged(rows);
    }

    function onRowDragEnter(event: React.DragEvent<HTMLTableRowElement>) {
        if(!(event.target instanceof HTMLElement))
            return;

        let rowElement: HTMLElement | null = event.target;

        while(rowElement) {
            if(rowElement.tagName === "TR") {
                break;
            }
            rowElement = rowElement.parentElement;
        }
        
        lastRowEntered = rowElement;
        
        console.log("onRowDragEnter ::: Dragged: " + printElementDetails(rowDragged) + ", over: " + printElementDetails(lastRowEntered));

        // Below is used to visualize the row change before it is done (it actually alters the table though and there are some indexing side effects)

        // let tbRows = Array.from(lastRowEntered.parentNode.children);
        // let idxRowEntered = tbRows.indexOf(lastRowEntered);
        // let idxRowDragged = tbRows.indexOf(rowDragged);
        
        // if(idxRowEntered > idxRowDragged) {
        //     lastRowEntered.after(rowDragged);
        // }
        // else if(idxRowEntered < idxRowDragged) {
        //     lastRowEntered.before(rowDragged);
        // }
    }

    function onRowDragOver(event: React.DragEvent<HTMLTableRowElement>) {
        // "By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element"
        event.preventDefault();
    }

    function onRowDragLeave(event: React.DragEvent<HTMLTableRowElement>) {
        console.log("onRowDragLeave ::: " + printElementDetails(event.target as HTMLElement));
    }

    function onRowDrop(event: React.DragEvent<HTMLTableRowElement>) {
        // This will not be called if the user cancels out of the drag action. OnDragEnd is the event that is called once the
        // drag action is complete (regardless of if something was moved)

        // "Call preventDefault() to prevent the browser default handling of the data (default is open as link on drop)"
        event.preventDefault();

        console.log("onRowDrop      ::: Dragged: " + printElementDetails(rowDragged) + ", over: " + printElementDetails(lastRowEntered));
        // let originalIdx = event.dataTransfer.getData("text");
        let idxRowDragged = getRowIndex(rowDragged as HTMLElement);
        let idxLastRowEntered = getRowIndex(lastRowEntered as HTMLElement);

        let row = rows.splice(idxRowDragged, 1)[0];
        rows.splice(idxLastRowEntered, 0, row);
    }

    if(!rows || rows.length < 1) {
        return null;
    }

    let tableBodyContent = rows.map((row: RecipeIngredientType) => {
        let textField = (
            <div>
                <IngredientAutoCompleteTextbox value={row.text}
                                                includeRecipeAsIngredient={true}
                                                onTextChanged={(value: string) => onTextChanged(value, row)}
                                                onSelectItem={(selectedValue: IIngredientSearchResult) => onSearchResultSelected(selectedValue, row)}
                                                />
                {/* display sub ingredients if the ingredient is another recipe */}
                {
                    row.isRecipe ?
                        <div className="ps-3" data-isingredientrecipe={true}>

                            {row.ingredientRecipe?.id ?
                            <div>
                                {/* //TODO2: Change to 'view' */}
                                <Link to={"/recipe/edit/" + row.ingredientRecipe?.id} state={{from: location}}>View {row.ingredientRecipe?.name}</Link>
                            </div> : null}

                            {row.ingredientRecipe?.sourceUrl ?
                            <div>
                                <a type="text" className="btn btn-link" href={row.ingredientRecipe?.sourceUrl} target="_blank" rel="noopener noreferrer">Go to source!</a>
                                <span className="fst-italic fw-lighter">This recipe is linked to an external website</span>
                            </div> : null}

                            {row.ingredientRecipe?.ingredients.map((recipeIngredient: RecipeIngredientType) => {
                                return (
                                    <div key={getItemKey(recipeIngredient)}>
                                        {recipeIngredient.isHeader ?
                                            <span className="fw-bolder">{recipeIngredient.text}</span> : 
                                            <div className="ps-2">
                                                <div className="d-inline me-1">
                                                    <IngredientQuantity isReadOnly={true} qtyWhole={recipeIngredient.qtyWhole} qtyNumerator={recipeIngredient.qtyNumerator} qtyDenominator={recipeIngredient.qtyDenominator} />
                                                </div>
                                                <div className="d-inline me-1">
                                                    <UnitOfMeasureDropdown isReadOnly={true} value={recipeIngredient.unit} />
                                                </div>
                                                <div className="d-inline me-1">
                                                    {recipeIngredient.text}
                                                </div>
                                                <div className="d-inline me-1">
                                                    <span className="fst-italic fw-lighter">{recipeIngredient.note ? `(${recipeIngredient.note})` : ""}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div> : null
                }
            </div>
        );

        if(row.isHeader) {
            textField = <div>
                <InputControl type="text" maxLength={50} additionalInputClass={"p-1" + (row.text ? " form-control-plaintext fw-bold" : "")} name="name" value={row.text} onChange={(e) => onTextChanged(e.target.value, row)} />
            </div>
        }

        let quantityCell = row.isHeader ? null : (
            <IngredientQuantity qtyWhole={row.qtyWhole} qtyNumerator={row.qtyNumerator} qtyDenominator={row.qtyDenominator} onQtyWholeChanged={onQtyWholeChanged} onQtyNumeratorChanged={onQtyNumeratorChanged} onQtyDenominatorChanged={onQtyDenominatorChanged} />
        );

        let unitField = row.isHeader || row.isRecipe ? null : <UnitOfMeasureDropdown value={row.unit} onChange={onUnitChanged} />

        let noteField = row.isHeader ? null : (
            <InputControl type="text" maxLength={50} additionalInputClass="fst-italic fw-lighter" name="note" value={row.note} onChange={onNoteChanged} />
        );
        
        return (
            <tr data-isheader={row.isHeader} data-isrecipe={row.isRecipe} key={getItemKey(row)} data-key={getItemKey(row)} draggable="true" onDragStart={onRowDragStart} onDrag={onRowDrag} onDragEnd={onRowDragEnd} onDragEnter={onRowDragEnter} onDragOver={onRowDragOver} onDragLeave={onRowDragLeave} onDrop={onRowDrop}>
                <td className={" border-end"}>
                    <button type="button" className="me-1 border-0" onClick={toggleRowType}><i className={row.isHeader ? "bi-arrow-right" : "bi-arrow-left"}></i></button>
                    <button type="button" className="me-1 border-0" onClick={removeRow}><i className="bi-dash"></i></button>
                </td>
                <td colSpan={row.isRecipe ? 3 : 1}>{textField}</td>
                {row.isRecipe ? null : <td>{quantityCell}</td>}
                {row.isRecipe ? null : <td>{unitField}</td>}
                <td>{noteField}</td>
            </tr>
        )}
    );

    return (
        <div className={"row " + styles.ingredientListStyle}>
            <table>
                <colgroup>
                    {/* Actions */}
                    <col span={1} style={{width: "10%"}} />
                    {/* Name */}
                    <col span={1} style={{width: "35%"}} />
                    {/* Qty */}
                    <col span={1} style={{width: "20%"}} />
                    {/* UoM */}
                    <col span={1} style={{width: "10%"}} />
                    {/* Note */}
                    <col span={1} style={{width: "25%"}} />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Note</th>
                    </tr>
                </thead>
                <tbody>
                    {tableBodyContent}
                </tbody>
            </table>
        </div>
    );
}