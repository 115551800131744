import { ApiErrorInfo } from "./errorInfo";


export default class Util {
    static getErrorsFromApiError(apiErrorInfo: ApiErrorInfo): string[] {
        let errors: string[] = [];
        if(apiErrorInfo) {
            apiErrorInfo.errors.forEach(x => errors.push(x));
            if(apiErrorInfo.fieldErrors.length > 0) {
                apiErrorInfo.fieldErrors.forEach(fieldErr => {
                    fieldErr.errors.forEach(err => {
                        errors.push(`${fieldErr.fieldName}: ${err}`);
                    })
                });
            }
        }
        return errors;
    }
    static secondsToTimeString(seconds: number): string {
        seconds = seconds || 0;
        // console.log("seconds: " + seconds);
        let minutes = 0;
        let hours = 0;
        let days = 0;
        if(seconds > 60){
            minutes = Math.trunc(seconds / 60);
            seconds = seconds % 60;
            // console.log("minutes: " + minutes);
            // console.log("seconds: " + seconds);
            if(minutes > 60) {
                hours = Math.trunc(minutes / 60);
                minutes = minutes % 60;
                // console.log("hours: " + hours);
                // console.log("minutes: " + minutes);
                if(hours > 24) {
                    days = Math.trunc(hours / 24);
                    hours = hours % 24;
                    // console.log("days: " + days);
                    // console.log("hours: " + hours);
                }
            }
        }

        let timeString = "";
        if(days > 0) {
            timeString += days + " day" + (days > 1 ? "s" : "");
        }

        if(hours > 0) {
            timeString += (!!timeString ? " " : "") + hours + " hour" + (hours > 1 ? "s" : "");
        }

        if(minutes > 0) {
            timeString += (!!timeString ? " " : "") + minutes + " minute" + (minutes > 1 ? "s" : "");
        }

        if(seconds > 0) {
            timeString += (!!timeString ? " " : "") + seconds + " second" + (seconds > 1 ? "s" : "");
        }

        timeString = timeString || "0 minutes";
        return timeString;
    }

    static secondsToMinutes(seconds: number): number{
        // 1 min 20 sec -> 80 sec -> 1.3 min
        seconds = seconds || 0;
        let minutes = 0;
        if(seconds > 0){
            if(seconds > 60) {
                minutes = Math.trunc(seconds / 60);
            }
            minutes += ((seconds % 60) + Number.EPSILON) / 60;
        }

        return minutes;
    }

    static minutesToSeconds(minutes: number): number {
        minutes = minutes || 0;
        return minutes * 60;
    }

    static millisecondsToDays(milliseconds: number): number {
        return Math.round(milliseconds / (1000 * 60 * 60 * 24));
    }

    static millisecondToWeeks(milliseconds: number): number {
        return Math.round(Util.millisecondsToDays(milliseconds) / 7);
    }

    static getTodayDateOnly(): Date {
        let today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }
    
    static getDaysSince(refDate: Date): number {
        const today = Util.getTodayDateOnly();
        return Util.millisecondsToDays(today.getTime() - refDate.getTime());
    }

    static getWeeksSince(refDate: Date): number {
        const daysSince = Util.getDaysSince(refDate);
        let weeksSince = Math.round(daysSince / 7);
        if(daysSince % 7 !== 0) {
            weeksSince++;
        }
        return weeksSince;
    }

    static getMonthsSince(refDate: Date): number {
        const today = Util.getTodayDateOnly();
        let monthsSince = 0;
        const yearDiff = today.getFullYear() - refDate.getFullYear();
        if(yearDiff > 0) {
            if(yearDiff > 1) {
                monthsSince = (yearDiff - 1) * 12;
            }
            monthsSince += 12 - refDate.getMonth();
            monthsSince += today.getMonth();
        }
        else {
            monthsSince = today.getMonth() - refDate.getMonth();
        }
        return monthsSince;
    }

    static getYearsSince(refDate: Date): number {
        const today = Util.getTodayDateOnly();
        let yearsSince = today.getFullYear() - refDate.getFullYear();

        // Check if ref date is not actually a full year apart
        if(yearsSince === 1 && today.getMonth() - refDate.getMonth() < 0)
        {
            yearsSince--;
        }
        return yearsSince;
    }

    static getAgeSinceDateAsString(refDate: Date): string {
        if(!refDate) {
            return "";
        }

        const timeDiffInDays = Util.getDaysSince(refDate);

        if(timeDiffInDays < 0) {
            return "in the future"
        }

        if(timeDiffInDays === 0) {
            return "today";
        }

        if(timeDiffInDays === 1) {
            return "yesterday";
        }

        // Up to '7 days ago' before switching to weeks
        if(timeDiffInDays < 8) {
            return `${timeDiffInDays} days ago`
        }

        // Difference between 'last week' and 'x weeks ago'
        if(Util.isLastWeek(refDate)) {
            return "last week"
        }

        const weeksSince = Util.getWeeksSince(refDate);

        if(weeksSince < 5) {
            return `${weeksSince} weeks ago`;
        }

        if(Util.isLastMonth(refDate)) {
            return "last month";
        }

        const monthsSince = Util.getMonthsSince(refDate);
        if(Util.isThisYear(refDate) || monthsSince < 3) {
            return `${monthsSince} months ago`;
        }
        
        if(Util.isLastYear(refDate)) {
            return `last year (${monthsSince} months ago)`;
        }

        const yearsSince = Util.getYearsSince(refDate);
        return `${yearsSince} years ago`;
    }

    static isLastWeek(refDate: Date): boolean {
        const today = Util.getTodayDateOnly();
        const timeDiffInDays = Util.millisecondsToDays(today.getTime() - refDate.getTime());

        // 0-6 starting with sunday
        const currentDay = today.getDay();
        return currentDay - timeDiffInDays < 0 && (timeDiffInDays - currentDay + 1) <= 7;
    }

    static isThisWeek(refDate: Date): boolean {
        const today = Util.getTodayDateOnly();
        const timeDiffInDays = Util.millisecondsToDays(today.getTime() - refDate.getTime());

        // 0-6 starting with sunday
        const currentDay = today.getDay();
        return currentDay - timeDiffInDays >= 0;
    }

    static isLastMonth(refDate: Date): boolean {
        const today = Util.getTodayDateOnly();
        const yearsDiff = today.getFullYear() - refDate.getFullYear();
        let lastMonth = today.getMonth() - 1;
        
        if(yearsDiff > 1) {
            return false;
        }
        else if(yearsDiff === 1 && lastMonth < 0) {
            // years are off by one & the last month is december (jan (0) - 1 = -1 (11 - December))
            // If last month is positive it means the years are different and the month is > 1 apart.
            lastMonth = 11;
            return refDate.getMonth() === lastMonth;
        }
        else if(yearsDiff === 0) {
            return refDate.getMonth() === lastMonth;
        }
        return false;
    }

    static isThisYear(refDate: Date): boolean {
        const today = Util.getTodayDateOnly();
        return today.getFullYear() === refDate.getFullYear();
    }

    static isLastYear(refDate: Date): boolean {
        const today = Util.getTodayDateOnly();
        return today.getFullYear() - refDate.getFullYear() === 1;
    }

    // Return empty string if id is <= 0
    static parseIdFromApi(value: string | undefined | null): string {
        value = value || "";
        const id = parseInt(value);
        return id <= 0 ? "" : String(id);
    }

    static parseInt(value: string | undefined | null): number {
        value = value || "";
        return parseInt(value) || 0;
    }

    static parseStr(value: string | undefined | null): string {
        if(value === undefined || value === null) {
            return "";
        }

        return String(value);
    }

    static parseIso8601Date(value: string): Date | null {
        let date = null;
        var r = /^\d{4}-\d{2}-\d{2}$/;
        if(value && r.test(value)) {
            const dateParts = value.split("-");
            date = new Date(Util.parseInt(dateParts[0]), Util.parseInt(dateParts[1]) - 1, Util.parseInt(dateParts[2]));
        }
        return date;
    }

    static parseDate(value: string): Date | null {
        if(!value) {
            return null;
        }

        let date = this.parseIso8601Date(value);
        if(!date) {
            date = new Date(value);
        }
        return date;
    }

    static parseDateStrict(value: string): Date {
        return this.parseDate(value) || new Date(2000, 0, 1);
    }

    static isSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    static toIso8601DateString(date: Date): string {
        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if(month.length < 2)
            month = "0" + month;

        if(day.length < 2)
            day = "0" + day;

        return `${year}-${month}-${day}`;
    }

    /**
     * Get the next day after the provided value (day + 1)
     * @param date 
     * @returns The date/time 24 hours after the provided date
     */
    static getNextDayDate(date: Date): Date {
        const nextDay = new Date(date);
        nextDay.setHours(date.getHours() + 24);
        return nextDay;
    }

    static ApiBoolean(value: string): boolean {
        return Boolean(value && value.toUpperCase() !== "FALSE")
    }

    static getRandomItem(array: Array<any>) {
        return array[Math.floor(Math.random() * array.length)];
    }

    static getTextSortOrder(a: string, b: string) {
        return a?.toUpperCase() > b?.toUpperCase() ? 1 : -1;
    }

    static boolToString(value: boolean)
    {
        return value ? "true" : "false";
    }
}