
import React from 'react';
import { GLHistoryItem } from "../../helpers/glHistoryHelper";
import useFetch from "../../hooks/useFetch";
import BusyIndicator from "../controls/busyIndicator";
import { GLHistoryListItem } from "./glHistoryListItem";
import Accordion from "../controls/accordion";
import styles from "./glHistory.module.scss"



type GLHistoryListingPropType = {
    items: GLHistoryItem[]
    addItemToGL(item: GLHistoryItem): Promise<boolean>
    onItemUpdated(item: GLHistoryItem): void
    onItemRemoved(item: GLHistoryItem): void
} & React.PropsWithChildren

export function GLHistoryListing(props: GLHistoryListingPropType) {
    const { fetchStatus } = useFetch();
    let items = props.items;

    return (
        <Accordion id={"purchaseHistory"} label="Last purchased">
            <BusyIndicator showIndicator={!fetchStatus.isComplete()}>
                {items.length > 0 ? 
                    <ul className={"list-group " +  styles.glHistoryStyle}>{items.map(item => <GLHistoryListItem key={item.id} item={item} onItemRemoved={props.onItemRemoved} addItemToGL={props.addItemToGL} onItemUpdated={props.onItemUpdated} />)}
                </ul> :
                <div className={`text-center ${!fetchStatus.isComplete() ? "visually-hidden" : ""}`}>
                    <span className="text-warning">There is no purchase history. Items will show here once they are marked as complete in the grocery list.</span>
                </div>}
            </BusyIndicator>
        </Accordion>
    );
}