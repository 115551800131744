

import React, { useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiErrorInfo } from '../helpers/errorInfo';
import Fetch from '../helpers/fetch';
import RecipeHelper, { ApiRecipeUsageType, RecipeType } from '../helpers/recipeHelper';
import Util from '../helpers/util';
import { useAuth } from '../providers/AuthProvider';
import NotFound from './utilities/notFound';
import UnitOfMeasureDropdown from "./controls/unitOfMeasureDropdown";
import IngredientQuantity from "./controls/ingredientQuantity";
import useFetch from "../hooks/useFetch";
import RecipeService from "../services/recipeService";
import AsyncStatusButton from "./controls/asyncStatusButton";
import RecipeUsage from "./recipeUsage";
import MarkAsConsumedBtn from "./recipe/markAsConsumedBtn";

function isWakeLockAvailable(): boolean {
    if ("wakeLock" in navigator) {
        return true;
    }

    return false
}

// Component used only for viewing of recipe
function Recipe() {

    const { userId } = useAuth();
    // let location = useLocation();
    // console.log("location: " + JSON.stringify(location));
    // console.log("state: " + location.state);
    // console.log("from: " + location.state?.from);
    // console.log("pathname: " + location.state?.from?.pathname);
    // let navigateToWhenDone = location.state?.from?.pathname || "/cookbook";
    // let navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(true);
    let [isNotFound, setIsNotFound] = useState(false);
    let [recipe, setRecipe] = useState<RecipeType>(() => RecipeHelper.initRecipe());
    const wakeLockRef = useRef<WakeLockSentinel>();
    let [isWakeLockEnabled, setIsWakeLockEnabled] = useState(false);
    let location = useLocation();
    
    let navigate = useNavigate();
    let { getJson, postJson} = useFetch();

    let params = useParams();

    useEffect(() => {
        if (params.recipeId) {
            RecipeService.get(getJson, params.recipeId).then(result => {
                if (result) {
                    let recipe = RecipeHelper.recipeFromApi(result);
                    setRecipe(recipe);
                }
                else {
                    // Recipe not found.
                    console.error("Recipe 'get' API call returned success but no data returned");
                    setIsNotFound(true);
                }
            }).catch((error) => {
                if (error instanceof ApiErrorInfo) {
                    if (error.isNotFound) {
                        setIsNotFound(true);
                    }
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else {
            console.error("Invalid data supplied to recipe view - no recipeId");
            setIsLoading(false);
        }

        return function () {
            wakeLockRef.current?.release().then(() => console.log("released wake lock in destructor..."));
        }
    }, [getJson, params.recipeId]);

    function editRecipe(event: React.MouseEvent<HTMLButtonElement>) {
        let selectedRecipeId = event.currentTarget.value;
        navigate("/recipe/edit/" + selectedRecipeId, {
            state: { from: location },
            replace: true
        });
    }

    function isWakeLockEnabledChanged(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            try {
                // this promise could reject based on condition of device (battery too low)
                navigator.wakeLock.request('screen').then((lockSentinel: WakeLockSentinel) => {
                    wakeLockRef.current = lockSentinel;
                    setIsWakeLockEnabled(true);
                    console.log("Screen Wake Lock released when requested:", wakeLockRef.current?.released);

                    // Catch when the lock is released
                    wakeLockRef.current.addEventListener('release', () => {
                        console.log("Screen Wake Lock released:", wakeLockRef.current?.released);
                        wakeLockRef.current = undefined;
                        setIsWakeLockEnabled(false);
                    });
                });
            } catch (error) {
                console.error(error);
            }
        }
        else if (wakeLockRef.current) {
            wakeLockRef.current.release().then(() => {
                console.log("manually disabled wake lock");
            });
        }
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isNotFound) {
        return <NotFound message="The recipe you are looking for does not exist or has been moved" />
    }

    let instructionIdx = 0;
    let ingredientGroupIdx = 0;
    let instructionGroupIdx = 0;

    let sourceUrl = recipe?.sourceUrl ? (
        <div className="form-group">
            <a type="text" className="btn btn-link" href={recipe.sourceUrl} target="_blank" rel="noopener noreferrer">Recipe source</a>
            <span><small><em>*This will open a new tab and may bring you to an external website. Use caution when using external links.</em></small></span>
        </div>
    ) : null;

    let showOverview = Boolean(sourceUrl);
    let overview = (
        <div>
            <div className="row">
                {sourceUrl}
            </div>
            {/* <div className="row">
                <div className="form-group">
                    <label>Total time</label>
                    <input type="text" className="form-control" value={Util.secondsToTimeString(recipe.totalTime)} readOnly />
                </div>
                <div className="form-group">
                    <label>Prep time</label>
                    <input type="text" className="form-control" value={Util.secondsToTimeString(recipe.prepTime)} readOnly />
                </div>
                <div className="form-group">
                    <label>Cook time</label>
                    <input type="text" className="form-control" value={Util.secondsToTimeString(recipe.cookTime)} readOnly />
                </div>
                <div className="form-group">
                    <label>Inactive time (rest/cool)</label>
                    <input type="text" className="form-control" value={Util.secondsToTimeString(recipe.inactiveTime)} readOnly />
                </div>
            </div> */}
        </div>
    );

    let userIsRecipeOwner = Boolean(recipe?.owner?.ownerId && userId && recipe?.owner?.ownerId === userId);

    return (
        <div className="d-flex flex-grow-1 flex-column">
            <div className="card shadow-lg mb-5 bg-body rounded flex-grow-1">
                <div className="card-header">
                    <h3 className="text-center">{recipe.name}</h3>
                    <h5 className="text-center">Chef: {recipe.owner?.alias || "anonymous"}</h5>
                    {isWakeLockAvailable() ? <div className="text-center">
                        <input type="checkbox" id={"chkWakeLockId"} className="me-1 mb-2" checked={isWakeLockEnabled} onChange={isWakeLockEnabledChanged} />
                        <label htmlFor={"chkWakeLockId"}>Cook mode <span className="fst-italic fs-smaller">(Prevent screen from turning off)</span></label>
                    </div> : null}
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-center">
                        {userIsRecipeOwner ? <button type="button" className="btn btn-secondary me-2" key={recipe.id} value={recipe.id} onClick={editRecipe}>Edit</button> : null}
                        <MarkAsConsumedBtn recipeId={recipe.id} />
                    </div>
                    {showOverview ?? <div className="card-body shadow-sm mt-3">
                        {overview}
                    </div>}
                    <div className="card-body shadow-sm mt-3">
                        <div className="row">
                            <h3>Ingredients</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Unit</th>
                                        <th scope="col">Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recipe.ingredients.map(x => {
                                        let dataKey = "";
                                        if (x.isHeader) {
                                            dataKey = "header_" + ingredientGroupIdx;
                                            ingredientGroupIdx++;
                                        }
                                        else {
                                            dataKey = x.id;
                                        }
                                        let text: string | JSX.Element = x.text;

                                        let trClass = "";
                                        let tdClass = "align-middle";
                                        let textTDStyle: any = {};

                                        if (x.isHeader) {
                                            text = <b>{x.text}</b>
                                            trClass = "table-secondary";
                                        }
                                        else {
                                            textTDStyle.paddingLeft = "1.25em";
                                        }

                                        return (
                                            <tr key={dataKey} className={trClass}>
                                                <td style={textTDStyle} className={tdClass}>{text}</td>
                                                <td className={tdClass}><IngredientQuantity isReadOnly={true} qtyWhole={x.qtyWhole} qtyNumerator={x.qtyNumerator} qtyDenominator={x.qtyDenominator} /></td>
                                                <td className={tdClass}><UnitOfMeasureDropdown isReadOnly={true} value={x.unit} /></td>
                                                <td className={tdClass}><span className="fst-italic fw-lighter">{x.note}</span></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-body shadow-sm mt-3">
                        <div className="row">
                            <h3>Instructions</h3>
                            <ul className="list-group">
                                {recipe.instructions.map(x => {
                                    let dataKey = "";
                                    if (x.isHeader) {
                                        dataKey = "header_" + instructionGroupIdx;
                                        instructionGroupIdx++;
                                    }
                                    else {
                                        dataKey = x.id;
                                    }

                                    let text: string | JSX.Element = x.text;
                                    let liStyle: any = {};
                                    let liClass = "list-group-item border-0";
                                    if (x.isHeader) {
                                        text = <b>{text}</b>
                                        liClass += " list-group-item-secondary";
                                    }
                                    else {
                                        liStyle.paddingLeft = "1.5em"
                                        instructionIdx++;
                                        text = instructionIdx + ") " + text;
                                    }
                                    return (
                                        <li key={dataKey} style={liStyle} className={liClass}>{text}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <RecipeUsage recipeId={recipe.id} />
        </div>
    );
}

export default Recipe;