
import React, { useState } from 'react';
import IngredientQuantity from "../../components/controls/ingredientQuantity";
import UnitOfMeasureDropdown from "../../components/controls/unitOfMeasureDropdown";
import GroceryListHelper, { GroceryListItemType } from "../../helpers/groceryListHelper";
import GroceryDepartmentDropdown from "../../components/controls/groceryDepartmentDropdown";
import { IngredientAutoCompleteTextbox } from "../controls/ingredientAutoCompleteTextbox";
import IngredientHelper, { IIngredientSearchResult } from "../../helpers/ingredientHelper";
import useFetch from "../../hooks/useFetch";
import { ApiErrorInfo } from "../../helpers/errorInfo";
import ErrorList from "../utilities/errorList";
import Util from "../../helpers/util";
import AsyncDeleteButton from "../controls/asyncDeleteButton";
import AsyncCheckboxStatusButton from "../controls/asyncCheckboxStatusButton";


/*
Grocery item can be defined in 3 ways
--Manually defining text, uom, department, etc
--recipe_ingredient will be defined and that will carry the 'ingredient' as a property
--ingredient can be selected using the ingredient autocomplete search box
*/


type GroceryListItemPropType = {
    isShoppingView: boolean,
    showUpArrow: boolean,
    showDownArrow: boolean,
    groceryListItem: GroceryListItemType,
    onItemChanged(oldItem: GroceryListItemType, newItem: GroceryListItemType): void
    onItemPurchased(item: GroceryListItemType): void
    removeItem(item: GroceryListItemType): void
    moveItemUp(item: GroceryListItemType): void
    moveItemDown(item: GroceryListItemType): void
} & React.PropsWithChildren


export function GroceryListItem(props: GroceryListItemPropType) {
    let { postJson } = useFetch();
    let [errors, setErrors] = useState<string[]>([]);

    function onItemTextChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.text = value;
        item.ingredient = null;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyWholeChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityWhole = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyNumeratorChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityNumerator = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onQtyDenominatorChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.quantityDenominator = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onUoMChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.unit = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onDepartmentChanged(value: string) {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.department = value;
        props.onItemChanged(props.groceryListItem, item);
    }

    function onDeleteClicked() : Promise<boolean> {
        setErrors([]);
        
        let item: GroceryListItemType = {...props.groceryListItem};
        const itemId = Util.parseInt(item.id);
        if(itemId <= 0) {
            return Promise.resolve(true);
        }

        return postJson("grocerylist/removeItem/" + itemId).then(() => {
            return true;
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setErrors(error.errors);
            }
            return false;
        });
    }

    function onFinalizeDeleteItem(isSuccess: boolean): void {
        if(isSuccess) {
            props.removeItem(props.groceryListItem)
        }
    }

    function onCompleteClicked() : Promise<boolean> {
        setErrors([]);

        let item: GroceryListItemType = {...props.groceryListItem};
        let apiItem = GroceryListHelper.convertItemToApiJson(item);

        let data = {
            glItem: apiItem,
            purchaseDate: Util.toIso8601DateString(new Date())
        }

        return postJson("grocerylist/markaspurchased", data).then((result: any) => {
            return true;
        }).catch((error: any) => {
            //TODO2: Error handling
            if(error instanceof ApiErrorInfo) {
                if(error.fieldErrors.length > 0) {
                    error.fieldErrors.forEach(fieldErr => {
                        fieldErr.errors.forEach(err => {
                            error.errors.push(err);
                        })
                    });
                }
                setErrors(error.errors);
            }
            return false;
        });
    }

    function onFinalizeComplete(isSuccess: boolean): void {
        if(isSuccess){
            // onSelectedChanged(true);
            props.onItemPurchased(props.groceryListItem);
        }
    }

    function onSearchResultSelected(selectedIngredient: IIngredientSearchResult)
    {
        let item: GroceryListItemType = {...props.groceryListItem};
        item.text = selectedIngredient.name;
        item.unit = selectedIngredient.defaultUnit;
        item.department = selectedIngredient.defaultDepartment;
        var ing = IngredientHelper.initIngredient();
        ing.name = selectedIngredient.name;
        item.ingredient = ing;
        props.onItemChanged(props.groceryListItem, item);
    }

    let isFromRecipe = !!props.groceryListItem.recipeIngredientId;
    let text = props.groceryListItem.text;
    let qtyWhole = props.groceryListItem.quantityWhole;
    let qtyNumerator = props.groceryListItem.quantityNumerator;
    let qtyDenominator = props.groceryListItem.quantityDenominator;
    let unit = props.groceryListItem.unit;
    let department = props.groceryListItem.department;

    if(props.isShoppingView) {
        return (
            <li className="list-group-item d-flex">
                <AsyncCheckboxStatusButton onClick={onCompleteClicked} onFinalized={onFinalizeComplete} />
                <div className="mx-2 flex-grow-1">
                    <span className="align-middle">{text}{qtyWhole || qtyNumerator || qtyDenominator ? " - " : ""}</span>
                    <div className="d-inline align-middle">
                        <IngredientQuantity isReadOnly={true} qtyWhole={qtyWhole} qtyNumerator={qtyNumerator} qtyDenominator={qtyDenominator} />
                    </div>
                    {unit === "each" ? null : (
                        <div className="d-inline align-middle">
                            <UnitOfMeasureDropdown isReadOnly={true} value={unit} /> 
                        </div>
                    )}
                    {isFromRecipe && props.groceryListItem.recipeIngredient?.recipe?.name ? (<span className="fw-light align-middle"> ({props.groceryListItem.recipeIngredient?.recipe?.name})</span>) : ""}
                </div>
                <div>
                    <AsyncDeleteButton performDelete={onDeleteClicked} onFinalized={onFinalizeDeleteItem} />
                </div>
                {errors.length > 0 ? <ErrorList errors={errors}/> : null}
            </li>
        );
    }

    return (
        <React.Fragment>
            <tr data-isfromrecipe={isFromRecipe}>
                <td className="visually-hidden">
                    <span>{props.groceryListItem.index}</span>
                </td>
                <td>
                    <button type="button" className={"btn p-0" + (!props.showUpArrow ? " invisible" : "")} onClick={() => props.moveItemUp(props.groceryListItem)}><i className="bi-arrow-up"></i></button>
                    <button type="button" className={"btn p-0" + (!props.showDownArrow ? " invisible" : "")} onClick={() => props.moveItemDown(props.groceryListItem)}><i className="bi-arrow-down"></i></button>
                </td>
                <td >
                    <IngredientAutoCompleteTextbox isReadOnly={isFromRecipe}
                                                    includeRecipeAsIngredient={false}
                                                    value={text}
                                                    onTextChanged={onItemTextChanged}
                                                    onSelectItem={onSearchResultSelected}
                                                    />
                </td>
                <td>
                    { (isFromRecipe) ? <div>&nbsp;<span>{props.groceryListItem.recipeIngredient?.recipe?.name}</span></div> : <span>&nbsp;</span> }
                </td>
                <td >
                    <IngredientQuantity isReadOnly={isFromRecipe} qtyWhole={qtyWhole} qtyNumerator={qtyNumerator} qtyDenominator={qtyDenominator} onQtyWholeChanged={(e) => onQtyWholeChanged(e.currentTarget.value)} onQtyNumeratorChanged={(e) => onQtyNumeratorChanged(e.currentTarget.value)} onQtyDenominatorChanged={(e) => onQtyDenominatorChanged(e.currentTarget.value)} />
                </td>
                <td >
                    <UnitOfMeasureDropdown isReadOnly={isFromRecipe} value={unit} onChange={(e) => onUoMChanged(e.currentTarget.value)} />
                </td>
                <td >
                    <GroceryDepartmentDropdown isReadOnly={false} value={department} onChange={(e) => onDepartmentChanged(e.currentTarget.value)} />
                </td>
                <td >
                    <AsyncDeleteButton performDelete={onDeleteClicked} onFinalized={onFinalizeDeleteItem} />
                </td>
            </tr>
            <tr className={errors.length < 1 ? "visually-hidden" : ""}>
                <td></td>
                <td></td>
                <td colSpan={5}><ErrorList errors={errors}/></td>
            </tr>
        </React.Fragment>
    );
}