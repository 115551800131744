import React, { useCallback, useState } from "react"
import useFetch from "../../hooks/useFetch"
import ModalDialog from "../utilities/modalDialog"
import DatePicker from "../controls/datePicker";
import GLHistoryHelper, { GLHistoryItem } from "../../helpers/glHistoryHelper";
import Util from "../../helpers/util";
import { ApiErrorInfo } from "../../helpers/errorInfo";
import ErrorList from "../utilities/errorList";
import IngredientQuantity from "../controls/ingredientQuantity";
import UnitOfMeasureDropdown from "../controls/unitOfMeasureDropdown";
import BusyIndicator from "../controls/busyIndicator";

type ModalEditGLHistoryItemPropType = {
    item: GLHistoryItem
    onComplete(isSuccess: boolean): void
} & React.PropsWithChildren

export default function ModalEditGLHistoryItem(props: ModalEditGLHistoryItemPropType) {
    let { postJson } = useFetch();
    const [item, setItem] = useState<GLHistoryItem>(props.item);
    const [errors, setErrors] = useState<string[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    
    const onOk = useCallback(async () => {
        setIsBusy(true);
        try {
            let apiItem = GLHistoryHelper.convertToApiJson(item);
            await postJson("glhistory/edit", apiItem);
            props.onComplete(true);
        } catch (error) {
            setErrors(Util.getErrorsFromApiError(error as ApiErrorInfo));
        } finally {
            setIsBusy(false);
        }
    }, [item, postJson, props]);

    const onDateChanged = useCallback((d: string) => {
        if(d) {
            setItem((prev) => {
                let historyItem = {...prev};
                historyItem.purchaseDate = Util.parseIso8601Date(d)!
                return historyItem;
            });
        }
    }, []);

    const onQtyWholeChanged = useCallback((val: string) => {
        setItem((prev) => {
            let historyItem = {...prev};
            historyItem.quantityWhole = val;
            return historyItem;
        });
    }, []);
    const onQtyNumeratorChanged = useCallback((val: string) => {
        setItem((prev) => {
            let historyItem = {...prev};
            historyItem.quantityNumerator = val;
            return historyItem;
        });
    }, []);
    const onQtyDenominatorChanged = useCallback((val: string) => {
        setItem((prev) => {
            let historyItem = {...prev};
            historyItem.quantityDenominator = val;
            return historyItem;
        });
    }, []);
    const onUoMChanged = useCallback((val: string) => {
        setItem((prev) => {
            let historyItem = {...prev};
            historyItem.unit = val;
            return historyItem;
        });
    }, []);

    return (
        <ModalDialog titleContent="Edit purchased item" onClose={() => props.onComplete(false)} onOk={onOk}>
            <BusyIndicator showIndicator={isBusy}>
                <p>{item.ingredient?.name} {item.recipeIngredient && item.recipeIngredient.recipe ? <span className="fw-light"> ({item.recipeIngredient.recipe.name})</span> : null}</p>
                <div className="form-group my-3">
                    <label>Quantity</label>
                    <IngredientQuantity isReadOnly={false}
                                        qtyWhole={item.quantityWhole}
                                        qtyNumerator={item.quantityNumerator}
                                        qtyDenominator={item.quantityDenominator}
                                        onQtyWholeChanged={(e) => onQtyWholeChanged(e.currentTarget.value)}
                                        onQtyNumeratorChanged={(e) => onQtyNumeratorChanged(e.currentTarget.value)}
                                        onQtyDenominatorChanged={(e) => onQtyDenominatorChanged(e.currentTarget.value)} />
                </div>
                <div className="form-group my-3">
                    <label>Unit</label>
                    <UnitOfMeasureDropdown isReadOnly={false} value={item.unit} onChange={(e) => onUoMChanged(e.currentTarget.value)} />
                </div>
                <div className="form-group my-3">
                    <label>Purchase date</label>
                    <div>
                        <DatePicker value={Util.toIso8601DateString(item.purchaseDate)} onChange={onDateChanged} />
                    </div>
                </div>
                <ErrorList errors={errors} />
            </BusyIndicator>
        </ModalDialog>
    );
}