import IngredientHelper, { ApiIngredientType, IngredientType } from "./ingredientHelper"
import RecipeHelper, { ApiRecipeIngredientType, RecipeIngredientType} from "./recipeHelper"
import Util from "./util"

export type ApiGLHistoryItem = {
    id: string,
    purchaseDate: string,
    recipeIngredientId: string,
    recipeIngredient: ApiRecipeIngredientType | null,
    ingredient: ApiIngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string
}

export type GLHistoryItem = {
    id: string,
    purchaseDate: Date,
    recipeIngredientId: string,
    recipeIngredient: RecipeIngredientType | null,
    ingredient: IngredientType | null,
    quantityWhole: string,
    quantityNumerator: string,
    quantityDenominator: string,
    unit: string
}

export default class GLHistoryHelper {
    static initFromApi(apiItem: ApiGLHistoryItem) : GLHistoryItem | null {
        if(!apiItem) {
            return null;
        }
        
        let item: GLHistoryItem = {
            id: apiItem.id,
            purchaseDate: Util.parseDateStrict(apiItem.purchaseDate),
            recipeIngredientId: Util.parseStr(apiItem.recipeIngredientId),
            recipeIngredient: apiItem.recipeIngredient ? RecipeHelper.recipeIngredientFromApi(apiItem.recipeIngredient) : null,
            ingredient: apiItem.ingredient ? IngredientHelper.ingredientFromApi(apiItem.ingredient) : null,
            quantityWhole: Util.parseStr(apiItem.quantityWhole),
            quantityNumerator: Util.parseStr(apiItem.quantityNumerator),
            quantityDenominator: Util.parseStr(apiItem.quantityDenominator),
            unit: Util.parseStr(apiItem.unit),
        };

        return item;
    }

    static convertToApiJson(item: GLHistoryItem): ApiGLHistoryItem {
        let apiItem: ApiGLHistoryItem = {
            id: item.id,
            purchaseDate: Util.toIso8601DateString(item.purchaseDate),
            recipeIngredientId: item.recipeIngredientId,
            recipeIngredient: null,
            ingredient: item.ingredient ? IngredientHelper.convertIngredientForApi(item.ingredient) : null,
            quantityWhole: item.quantityWhole,
            quantityNumerator: item.quantityNumerator,
            quantityDenominator: item.quantityDenominator,
            unit: item.unit,
        }
        return apiItem;
    }
}