import { useEffect, useState } from "react";
import BusyIndicator, { BusyIndicatorSize } from "./busyIndicator";

type AsyncStatusButtonPropType = {
    btnClassName?: string,
    buttonType?: typeof AsyncStatusButtonType[keyof typeof AsyncStatusButtonType], // Only allow values defined in that const
    disabled?: boolean,
    displayClassName?: string,
    margin?: string,
    onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<boolean>,
    onFinalized?(isSuccess: boolean): void
} & React.PropsWithChildren

export const AsyncStatusButtonType = {
    SECONDARY: "SECONDARY",
    DELETE: "DELETE",
    ICON: "ICON"
}

export default function AsyncStatusButton(props: AsyncStatusButtonPropType) {
    const [ isBusy, setIsBusy ] = useState(false);
    const [ isSuccess, setIsSuccess ] = useState<boolean | null>(null);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;

        if(typeof isSuccess === 'boolean')
        {
            timeoutId = setTimeout(() => {
                timeoutId = null;
                setIsSuccess(null);
                
                if(props.onFinalized) {
                    props.onFinalized(isSuccess);
                }
            }, 1500);
        }

        // Cleanup function to avoid memory leaks. When component is unmounted (leaves the screen) any
        // subscriptions, timers, timeouts, etc must be disposed of.
        return () => {
            if(timeoutId || timeoutId === 0) {
                clearTimeout(timeoutId);
            }
        };
    }, [isSuccess, props]);

    function onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if(isBusy) {
            return;
        }

        setIsBusy(true);
        // Before action
        props.onClick(e).then((isSuccess: boolean) => {
            setIsBusy(false);
            setIsSuccess(isSuccess);
        });
    }

    const isIndicatorActive = typeof isSuccess === 'boolean';
    const buttonType = props.buttonType || AsyncStatusButtonType.SECONDARY;
    const isIconButton = buttonType === AsyncStatusButtonType.DELETE || buttonType === AsyncStatusButtonType.ICON;

    let content: React.ReactNode = props.children;
    let displayClassName = props.displayClassName || "d-inline-block";
    let indicatorClassName = displayClassName;
    let indicatorSize = undefined;
    let btnType: "submit" | "reset" | "button" | undefined = "button";
    let btnClass = props.btnClassName || "btn btn-secondary ";

    if(buttonType === AsyncStatusButtonType.DELETE) {
        content = <i className="bi-trash3"></i>
    }

    if(isIconButton) {
        // indicatorClassName += " d-inline-block";
        indicatorSize = BusyIndicatorSize.SMALL;
        btnClass = " btn px-1 py-0 border-0"; // remove border when disabled
    }

    if(isIndicatorActive) {
        btnClass += isSuccess ? " bg-success" : " bg-danger";
    }

    if(props.margin) {
        indicatorClassName += ` ${props.margin}`;
    }

    return (
        <BusyIndicator showIndicator={isBusy} indicatorSize={indicatorSize} className={indicatorClassName}>
            <button className={btnClass} type={btnType} disabled={isBusy || props.disabled} onClick={onClick}>
                {content}
            </button>
        </BusyIndicator>
    )
}