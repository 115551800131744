
import React from 'react';
import { GLHistoryItem } from "../../helpers/glHistoryHelper";
import useFetch from "../../hooks/useFetch";
import AsyncDeleteButton from "../controls/asyncDeleteButton";
import AsyncStatusButton, { AsyncStatusButtonType } from "../controls/asyncStatusButton";
import IngredientQuantity from "../controls/ingredientQuantity";
import UnitOfMeasureDropdown from "../controls/unitOfMeasureDropdown";
import Util from "../../helpers/util";
import { useOverlay } from "../../providers/OverlayProvider";
import ModalEditGLHistoryItem from "./modalEditGLHistoryItem";


type GLHistoryItemPropType = {
    item: GLHistoryItem,
    onItemUpdated(item: GLHistoryItem): void,
    onItemRemoved(item: GLHistoryItem): void,
    addItemToGL(item: GLHistoryItem): Promise<boolean>
} & React.PropsWithChildren

export function GLHistoryListItem(props: GLHistoryItemPropType) {
    const item = props.item;
    const { postJson } = useFetch();
    let { setOverlayContent } = useOverlay();

    async function deleteItem(): Promise<boolean> {
        try {
            await postJson("glhistory/remove/" + item.id);
            return true;
        } catch (error) {
            return false;
        }
    }

    async function editItem(): Promise<boolean> {
       return new Promise((resolve) => {

            let onComplete = function(isSuccess: boolean) {
                setOverlayContent(null);
                resolve(isSuccess);
            }

            setOverlayContent((
                <ModalEditGLHistoryItem item={props.item} onComplete={onComplete}/>
            ));
        });
    }

    function onEditFinalized(isSuccess: boolean) {
        if(isSuccess) {
            props.onItemUpdated(item);
        }
    }

    function onRemoveFinalized(isSuccess: boolean): void {
        if(isSuccess) {
            props.onItemRemoved(item);
        }
    }
    
    const isQtySet = item.quantityWhole || item.quantityNumerator || item.quantityDenominator;
    return (
        <li className="list-group-item d-flex px-2">
            <div className="d-flex align-items-center">
                <AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={() => props.addItemToGL!(item)}><i className="bi-arrow-up"></i></AsyncStatusButton>
            </div>
            <div className="flex-grow-1 align-items-center">
                <span className="fs-5">{item.ingredient?.name}&nbsp;</span>
                <IngredientQuantity isReadOnly={true} qtyWhole={item.quantityWhole} qtyNumerator={item.quantityNumerator} qtyDenominator={item.quantityDenominator} />
                {item.unit === "each" || !isQtySet ? null : <UnitOfMeasureDropdown isReadOnly={true} value={item.unit} /> }
                {item.recipeIngredient && item.recipeIngredient.recipe ? <span className="fw-light">({item.recipeIngredient.recipe.name})</span> : null}
                <div>
                    <span className="fs-smaller">Purchased <span className="fw-semibold">{Util.getAgeSinceDateAsString(item.purchaseDate)}</span> - {item.purchaseDate.toDateString()}</span>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="d-inline mx-2">
                    <AsyncStatusButton buttonType={AsyncStatusButtonType.ICON} onClick={editItem} onFinalized={onEditFinalized}>
                        <i className="bi-pencil" />
                    </AsyncStatusButton>
                </div>
                <AsyncDeleteButton performDelete={deleteItem} onFinalized={onRemoveFinalized}></AsyncDeleteButton>
            </div>
        </li>
    );
}
