import React from 'react';

type BusyIndicatorProps = {
    showIndicator: boolean
    className?: string,
    indicatorSize?: typeof BusyIndicatorSize[keyof typeof BusyIndicatorSize]
} & React.PropsWithChildren

export const BusyIndicatorSize = {
    SMALL: "sm",
    LARGE: "lg"
}

export default function BusyIndicator(props: BusyIndicatorProps) {
    let className = "position-relative";
    let indicatorSize = props.indicatorSize ? props.indicatorSize : "lg";
    if(props.className) {
        className += " " + props.className;
    }
    
    return (
        <div className={className}>
            {props.children}
            {props.showIndicator ?
            <div>
                <div className={"position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center bg-dark-subtle opacity-50"}>
                </div>
                <div className={"position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center"}>
                    <div className={`spinner-border spinner-border-${indicatorSize} text-info`} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div> : null }
        </div>
    );
}