import AsyncStatusButton, { AsyncStatusButtonType } from "./asyncStatusButton";

type AsyncDeleteButtonPropType = {
    disabled?: boolean,
    displayClassName?: string,
    margin?: string,
    performDelete(): Promise<boolean>,
    onFinalized(isSuccess: boolean): void
} & React.PropsWithChildren

export const AsyncDeleteButtonType = {
    SECONDARY: "SECONDARY",
    DELETE: "DELETE",
    ICON: "ICON"
}

export default function AsyncDeleteButton(props: AsyncDeleteButtonPropType) {
    return (
        <AsyncStatusButton buttonType={AsyncStatusButtonType.DELETE} disabled={props.disabled} onClick={props.performDelete} onFinalized={props.onFinalized}></AsyncStatusButton>
    )
}